'use client'

import { useRouter } from 'next/navigation'
import { DrupalTerminalLocationNode } from '@/lib/api_definitions/node/terminal_location'

interface LocationButtonProps {
  location: DrupalTerminalLocationNode
}

export const LocationButton = ({ location }: LocationButtonProps) => {
  const router = useRouter()

  const handleClick = (locationId: string) => {
    router.replace(`/${locationId}`)
  }

  return (
    <button
      onClick={() => handleClick(location.id)}
      className="rounded bg-white p-4 font-semibold uppercase text-blue-500"
      key={location.id}
    >
      {location.title}
    </button>
  )
}
